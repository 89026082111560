import React from "react";
import { Link } from "gatsby";
import { Layout, SubpageHeader } from "../components";
import Scrollspy from "react-scrollspy";

const OfferPage = ({ location: { pathname } }) => {
    return (
        <Layout
            seo={{
                title: "Usługi marketingu internetowego",
                description:
                    "Każdego dnia pomagamy w rozwoju wielu firm z różnych branż w obszarze marketingu internetowego. Nasze wieloletnie doświadczenie i sukcesy potwierdzają naszą skuteczną realizację usług marketingu internetowego.  Jesteśmy ekspertami od reklamy internetowej. Kampanii PPC, analityka oraz social media. Poznaj nasze usługi.",
                href: pathname,
                lang: "pl",
            }}
        >
            <SubpageHeader title="usługi" />
            <section className="container-fluid page_offer offer_section_1">
                <div className="row">
                    <div className="col-md-3">
                        <div className="page_offer-nav">
                            <Scrollspy
                                items={[
                                    "specjalizacje",
                                    "ppc",
                                    "social-media",
                                    "analityka",
                                ]}
                                currentClassName="current"
                                offset={150}
                            >
                                <li className="current">
                                    <a href="#specjalizacje">Specjalizacje</a>
                                </li>
                                <li>
                                    <a href="#ppc">PPC</a>
                                </li>
                                <li>
                                    <a href="#social-media">Social Media</a>
                                </li>
                                <li>
                                    <a href="#analityka">Analityka</a>
                                </li>
                            </Scrollspy>
                        </div>
                    </div>
                    <div className="col-md-8 offset-md-1">
                        <span
                            className="anchor_offer"
                            id="specjalizacje"
                        ></span>
                        <div className="page_offer-tile">
                            <h3>Specjalizacje</h3>
                            <div className="row">
                                <div className="col-lg-8">
                                    <strong>Siła doświadczenia.</strong>
                                    <p>
                                        Każdego dnia pomagamy w rozwoju wielu
                                        firm z różnych branż. Dzięki naszemu
                                        wieloletniemu doświadczeniu i sukcesom,
                                        są wśród nich takie, w których
                                        uzyskaliśmy pełną specjalizację i
                                        osiągamy imponujące wyniki.
                                    </p>
                                </div>
                                <div className="col-lg-4 single-offers-col">
                                    <ul>
                                        <li>
                                            <Link to="/marketing-dla-ecommerce/">
                                                Marketing dla e-commerce
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/marketing-dla-deweloperow/">
                                                Marketing dla deweloperów
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/marketing-produktow-inwestycyjnych/">
                                                Marketing dla produktów
                                                inwestycyjnych
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/marketing-dla-hoteli/">
                                                Marketing dla hoteli
                                            </Link>
                                        </li>
                                        {/* <li>Marketing dla gamingu</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <span className="anchor_offer" id="ppc"></span>
                        <div className="page_offer-tile">
                            <h3>PPC</h3>
                            <div className="row">
                                <div className="col-lg-8">
                                    <strong>
                                        Bądź tam gdzie Twoi klienci.
                                    </strong>
                                    <p>
                                        Przemyślane słowa kluczowe, precyzyjnie
                                        dobrani użytkownicy, przejrzyste
                                        rozliczenia i w pełni mierzalne efekty.
                                        Nie bez powodu płatne kampanie Google i
                                        Facebook Ads to podstawa sukcesu każdej
                                        naszej kampanii.
                                    </p>
                                </div>
                                <div className="col-lg-4 single-offers-col">
                                    <ul>
                                        <li>
                                            <Link to="/audyt-kampanii-google-facebook-ads/">
                                                Audyt kampanii Google/Facebook
                                                Ads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-googleads-search/">
                                                Kampanie Google Ads search
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-wideo-na-youtube/">
                                                Kampanie YouTube Ads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-w-sieci-reklamowej-gdn/">
                                                Kampanie w sieci reklamowej
                                                (GDN)
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-produktowe-product-listing-ads/">
                                                Kampanie PLA
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/tiktok-ads/">
                                                Kampanie TikTok Ads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-facebook-ads/">
                                                Kampanie Facebook Ads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-na-instagramie/">
                                                Kampanie na Instagramie
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-linkedin-ads/">
                                                Kampanie na LinkedIn
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-zagraniczne/">
                                                Kampanie zagraniczne
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/banery-statyczne-i-dynamiczne/">
                                                Banery statyczne i dynamiczne
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <span className="anchor_offer" id="social-media"></span>
                        <div className="page_offer-tile">
                            <h3>Social Media</h3>
                            <div className="row">
                                <div className="col-lg-8">
                                    <strong>
                                        Wyróznij się w Social Media.
                                    </strong>
                                    <p>
                                        Kreatywne pomysły, angażujące akcje,
                                        mnóstwo nowych lajków i followersów — to
                                        nasza propozycja na social media Twojej
                                        firmy.
                                    </p>
                                </div>
                                <div className="col-lg-4 single-offers-col">
                                    <ul>
                                        <li>
                                            <Link to="/audyt-kanalow-social-media/">
                                                Audyt social media
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/strategia-komunikacji-social-media/">
                                                Strategia komunikacji <br />{" "}
                                                social media
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/prowadzenie-strony-na-facebooku/">
                                                Prowadzenie strony na Facebooku
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/prowadzenie-konta-instagram/">
                                                Prowadzenie konta Instagram
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/prowadzenie-strony-na-linkedin/">
                                                Prowadzenie strony na LinkedIn
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-facebook-ads/">
                                                Kampanie Facebook Ads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/kampanie-na-instagramie/">
                                                Kampanie na Instagramie
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/tiktok-ads/">
                                                Kampanie TikTok Ads
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/chatbot-w-komunikacji-marki/">
                                                Chatbot
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <span className="anchor_offer" id="analityka"></span>
                        <div className="page_offer-tile">
                            <h3>Analityka</h3>
                            <div className="row">
                                <div className="col-lg-8">
                                    <strong>Data is king.</strong>
                                    <p>
                                        Tam gdzie są dane, tam pojawia się pole
                                        do optymalizacji prowadzonych działań
                                        marketingowych. Tym jakie dane
                                        gromadzić, jak je zbierać i jak to
                                        przełożyć na rozwój Twojego biznesu
                                        zajmiemy się my.
                                    </p>
                                </div>
                                <div className="col-lg-4 single-offers-col">
                                    <ul>
                                        <li>
                                            <Link to="/konfiguracja-i-wdrozenie-google-analytics-4/">
                                                Konfiguracja i wdrożenie Google
                                                Analytics 4
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/wdrozenie-analityki/">
                                                Wdrożenie analityki
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/testy-ab-google-optimize/">
                                                Testy A/B
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/analiza-strony-internetowej-przed-startem-kampanii-ppc/">
                                                Analiza strony internetowej
                                                przed startem kampanii PPC
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OfferPage;
